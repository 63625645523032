const timers = {}

const debounce = (name, callback, wait) => {
    if ((name in timers)) {
        window.clearTimeout(timers[name])
        delete timers[name]
    }

    timers[name] = window.setTimeout(() => {
        callback()
    }, wait)
}

const unbounce = (name) => {
    window.clearTimeout(timers[name])
    delete timers[name]
}

const isMac = (/mac|ipod|iphone|ipad|macos/i.test(navigator.userAgentData?.platform || navigator.platform))

export { debounce, unbounce, isMac }
