import TippyJS from 'tippy.js'

const instances = []

export default function tippy (el, options) {
    // Adding onDestroy event
    let onDestroy = () => {}
    if ('onDestroy' in options) {
        onDestroy = options.onDestroy
    }
    options.onDestroy = (instance) => {
        onDestroy(instance)
        const index = instances.findIndex(x => x === instance)
        if (index !== -1) {
            instances.splice(index, 1)
        }
    }

    const instance = TippyJS(el, options)
    if (Array.isArray(instance)) {
        instance.forEach(i => instances.push(i))
        return instance
    }

    instances.push(instance)
    return instance
}

export function destroyAll () {
    instances.forEach(instance => instance.destroy())
}
