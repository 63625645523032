<template>
    <div :class="{'is-safari-ios': isSafariOnMobile}">
        <router-view/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { destroyAll } from '@/resources/Tippy'
import Unsplash from '@/assets/unsplash.js'

export default {
    data () {
        return {
            darkMode: false
        }
    },
    computed: {
        ...mapGetters({
            account: 'agents/account'
        }),
        unsplash () {
            const key = new Date().toISOString().split('T')[0].substr(5)
            if (key in Unsplash && Unsplash[key]) return Unsplash[key]
            return Unsplash.default
        },
        isSafariOnMobile () {
            // Testing iOS
            return /ipad|iphone|ipod/i.test(navigator.userAgentData?.platform || navigator.platform) && /safari/i.test(navigator.userAgent)
        }
    },
    watch: {
        darkMode (to) {
            if (to) {
                document.querySelector('body').classList.add('dark-mode')
            } else {
                document.querySelector('body').classList.remove('dark-mode')
            }
        },
        'account.dark_mode': {
            handler (to) {
                if (typeof to === 'boolean') {
                    this.setDarkMode(to, true)
                }
            },
            immediate: true
        },
        $route (to, from) {
            destroyAll()
        }
    },
    mounted () {
        if (window.matchMedia) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
                this.setDarkMode(e.matches)
            })

            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.setDarkMode(true)
            }
        }

        const prefetchLink = document.createElement('link')
        prefetchLink.rel = 'prefetch'
        prefetchLink.as = 'image'
        prefetchLink.href = this.unsplash.url
        document.head.appendChild(prefetchLink)
    },
    methods: {
        setDarkMode (isDark, force) {
            if (this.account && !force && this.account.dark_mode !== null) {
                // We don't change the dark mode settings if it's not from a user's decision (force)
                return
            }

            // We come this far for two reasons :
            // 1. We forced it (user asked for change manually)
            // 2. Based on the browser settings, when the user has no preferences

            // Be cause of 2., we cannot save here! Neither set the value on the account object!
            // So ... we need a data value here!
            this.darkMode = isDark
        }
    }
}
</script>
