import defaults from './utils'
import Api from '@/resources/Api'

const baseState = () => ({
    items: {},
    mapId: {},
    cursors: {}, // Provides an indicator if there is pending messages and if it's loading
    messages: {} // A map of conversation_id that list the message_id in proper order already
})

const messages = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => ({ instance } = {}) => {
            if (!instance) return null
            if (instance.id.startsWith('m-')) {
                return `conversations/${instance.conversation_id}/messages/` + instance.id.substring(2)
            }
            return `conversations/${instance.conversation_id}/notes/` + instance.id.substring(2)
        },
        conversation: (state) => (id) => {
            return Object.values(state.items).filter(x => !x.$removed && x.conversation_id === id).sort((a, b) => a.timestamp_ts - b.timestamp_ts)
            /*
            if (!(id in state.messages)) return []
            return state.messages[id].map(x => state.items[x])
            */
        },
        loading: (state) => (id) => {
            if (!(id in state.cursors)) return false
            return state.cursors[id].loading
        }
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        },
        ADD_ENTRY: (state, { targetId, document, getters }) => {
            if (document.type === 'MESSAGE') {
                if (!('attachments' in document)) {
                    document.attachments = []
                }
                // Special structure for messages, where we group local messages into threads
                // We disable for now. We need a better design in the UI first
                // As we need to be able to display read, attachment and translation at the thread level, not the message-group level

                const thread = {
                    id: document.id,
                    message: document.message,
                    // translated: document.translated,
                    timestamp: document.timestamp,
                    timestamp_ts: document.timestamp_ts,
                    attachments: document.attachments
                }

                delete document.message
                delete document.attachments

                document.threads = [thread]
                /*
                if (document.agent_id) document.senderId = `a-${document.agent_id}`
                else if (document.contact_id) document.senderId = `c-${document.contact_id}`

                if (state.messages[document.conversation_id]?.length > 0) {
                    const previous = state.items[state.messages[document.conversation_id][state.messages[document.conversation_id].length - 1]]
                    if (previous) {
                        const deltaCreated = Math.abs(previous.timestamp - document.timestamp)
                        if (previous.type === document.type && previous.way === document.way && previous.senderId === document.senderId && deltaCreated < 2 * 60 * 1000) { // 2 minutes
                            previous.threads.push(document.threads[0])
                            previous.timestamp = document.timestamp
                            previous.id = document.id
                            return
                        }
                    }
                }
                */
            }

            Object.defineProperties(document, {
                agent: {
                    get () {
                        if (!this.agent_id) return null
                        return getters['agents/get'](this.agent_id)
                    }
                },
                contact: {
                    get () {
                        if (!this.contact_id) return null
                        return getters['contacts/get'](this.contact_id)
                    }
                },
                target: {
                    get () {
                        if (!this.target_id) return null
                        return getters['agents/get'](this.target_id)
                    }
                }
            })

            state.items[targetId] = document
            if (state.messages[document.conversation_id]) {
                state.messages[document.conversation_id].push(targetId)
            }
        },
        SET_CURSOR: (state, { id, cursor }) => {
            /**
             * SET_CURSOR also indicate that loading is done
             */
            state.cursors[id] = {
                cursor,
                loading: false
            }
        },
        SET_LOADING: (state, { id, loading }) => {
            if (!(id in state.cursors)) {
                state.cursors[id] = {
                    cursor: null,
                    loading
                }
            } else {
                state.cursors[id].loading = loading
            }
        },
        SET_PENDING: (state, id) => {
            if (state.items[id]?.$pendingTimer) {
                clearTimeout(state.items[id]?.$pendingTimer)
            }

            let duration = 10000
            if (state.items[id].source === 'CHAT') {
                duration = 0
            } else if (state.items[id].delay) {
                duration = state.items[id].delay * 1000
            }

            state.items[id].$pending = true
            state.items[id].$pendingTimer = setTimeout(() => {
                if (id in state.items) {
                    delete state.items[id].$pending
                    delete state.items[id].$pendingTimer
                }
            }, duration)
        },
        ORDER_ENTRIES: (state, id) => {
            // Order the entries and add them as a mapping to messages[id]
            state.messages[id] = []
            const messages = Object.values(state.items).filter(x => x.conversation_id === id).sort((a, b) => a.timestamp_ts - b.timestamp_ts)
            messages.forEach(entry => {
                /*
                // We disable the grouping for now
                if (entry.type === 'MESSAGE') {
                    let previous = null
                    if (state.messages[id].length > 0) {
                        previous = state.items[state.messages[id][state.messages[id].length - 1]]
                    }

                    if (previous) {
                        const deltaCreated = Math.abs(previous.timestamp - entry.timestamp)
                        if (previous.type === entry.type && previous.way === entry.way && previous.senderId === entry.senderId && deltaCreated < 2 * 60 * 1000) { // 2 minutes
                            previous.threads.push(entry.threads[0])
                            previous.timestamp = entry.timestamp
                            previous.id = entry.id

                            // REMOVE_ENTRY
                            if (entry.id && state.items[entry.id]) {
                                if (state.items[entry.id].$id && state.mapId[state.items[entry.id].$id]) {
                                    delete state.mapId[state.items[entry.id].$id]
                                }
                                delete state.items[entry.id]
                            }
                            return
                        }
                    }
                }
                */

                state.messages[id].push(entry.id)
            })
        },
        UPDATE_ENTRY_ID: (state, { oldId, newId }) => {
            if (oldId in state.items) {
                if (!(newId in state.items)) { // We test because it might be already in it
                    state.items[newId] = state.items[oldId]
                    if (state.items[oldId].$id && state.items[oldId].$id in state.mapId) {
                        state.mapId[state.items[oldId].$id] = newId
                    }

                    if ('$sending' in state.items[newId]) {
                        delete state.items[newId].$sending
                    }
                    if ('$cancelToken' in state.items[newId]) {
                        delete state.items[newId].$cancelToken
                    }
                }
                delete state.items[oldId] // But we delete the old one
            }
        }
    },
    actions: {
        ...defaults.actions,
        async postAdd ({ commit }, { id, document }) {
            if (id.substr(0, 2) === 'm-' && document && document.status === 'PENDING' && document.source !== 'CHAT') {
                commit('SET_PENDING', id)
            }
        },
        async postUpdate ({ commit }, { id, document }) {
            if (id.substr(0, 2) === 'm-' && document.status === 'PENDING' && document.source !== 'CHAT') {
                commit('SET_PENDING', id)
            }
        },
        async load ({ getters, commit }, { id, cursor = null }) {
            if (getters.loading(id)) return
            commit('SET_LOADING', { id, loading: true })
            const result = await Api.stream(`conversations/${id}/messages/`, { params: { limit: 20, before: cursor } })
            commit('ORDER_ENTRIES', id)
            if (result.total === 20) {
                const cursor = getters.conversation(id)[0].timestamp_ts
                commit('SET_CURSOR', { id, cursor })
            } else {
                commit('SET_CURSOR', { id, cursor: null })
            }
        },
        async next ({ state, dispatch }, conversationId) {
            if (!(conversationId in state.cursors)) {
                console.warn('No conversations ? Should we load ?')
                return false
            }

            if (state.cursors[conversationId]?.cursor === null) return false // We have loaded everything
            await dispatch('load', { id: conversationId, cursor: state.cursors[conversationId].cursor })
        },
        async undo ({ state }, id) {
            const message = state.items[id]
            if (id.indexOf('-tmp-') > -1) {
                // Message is currently being sent, we can cancel it !
                message.$cancelToken.cancel()
                return message
            }

            if (!id.startsWith('m-')) {
                throw Error('Only a message can be undone.')
            }

            return await Api.post(`/conversations/${message.conversation_id}/messages/${id.substring(2)}/undo`)
        },
        updateEntryId ({ commit }, { oldId, newId }) {
            commit('UPDATE_ENTRY_ID', { oldId, newId })
        }
    }
}

export default messages
