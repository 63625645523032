<template>
    <div class="avatar__wrapper">
        <div :class="[customClass, 'avatar', 'transit--ultrafast', {'is-loaded': state === 'success', 'avatar--unauth': !noWarning && !contact?.is_trusted}]" @mouseenter="renderTippy">
            <template v-if="avatar === '@here'">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon__avatar transit transit--fast">
                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                    </svg>
                </span>
            </template>
            <template v-else-if="avatar === '@everyone'">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon__avatar transit transit--fast">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                    </svg>
                </span>
            </template>
            <template v-else>
                <span v-if="state !== 'success'">
                    <span v-for="(letter, index) in getInitials()" :key="index">{{ letter }}</span>
                </span>
                <img :src="avatar" @load="state = 'success'" @error="onError" v-if="state !== 'error'" loading="lazy" />
            </template>
        </div>
        <div v-if="contact?.isConnected" class="avatar__status"></div>
        <svg v-if="!noWarning && !contact?.is_trusted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="avatar__badge u--icon--orange">
            <path fill-rule="evenodd" d="M10.339 2.237a.531.531 0 0 0-.678 0 11.947 11.947 0 0 1-7.078 2.75.5.5 0 0 0-.479.425A12.11 12.11 0 0 0 2 7c0 5.163 3.26 9.564 7.834 11.257a.48.48 0 0 0 .332 0C14.74 16.564 18 12.163 18 7c0-.538-.035-1.069-.104-1.589a.5.5 0 0 0-.48-.425 11.947 11.947 0 0 1-7.077-2.75ZM10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 6Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clip-rule="evenodd" />
        </svg>
    </div>
</template>

<script>
import slugify from '@/plugins/slugify'
import tippy from '@/resources/Tippy'

export default {
    props: {
        contact: {
            required: true,
            type: Object,
            default: null
        },
        hover: {
            required: false,
            type: Boolean,
            default: false
        },
        hoverMessage: {
            required: false,
            type: String,
            default: null
        },
        noWarning: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            state: 'loading',
            tippy: null
        }
    },
    watch: {
        'contact.avatar': {
            handler (to, from) {
                if (!to) {
                    this.state = 'error'
                } else if (to !== from) {
                    if (to === '@everyone' || to === '@here') {
                        this.state = 'success'
                    } else {
                        this.state = 'loading'
                    }
                }
            },
            immediate: true
        }
    },
    computed: {
        customClass () {
            if (this.state === 'success') return null

            let hash = 0
            if (this.contact && this.contact.email) {
                for (let i = 0; i < this.contact.email.length; i++) {
                    hash += this.contact.email.charCodeAt(i)
                }
            }
            return 'avatar-' + Math.abs(hash) % 5
        },
        avatar () {
            if (this.contact?.email === 'contact@getfernand.com' || this.contact?.email === 'support@getfernand.com') {
                return this.$root.darkMode ? '/images/avatars/fernand-avatar-dark.png' : '/images/avatars/fernand-avatar-light.png'
            }
            return this.contact?.avatar
        }
    },
    methods: {
        renderTippy () {
            if (!this.hover) return
            if (this.tippy) {
                this.tippy.destroy()
                this.tippy = null
            }

            let content = this.hoverMessage
            if (!content) {
                content = `<strong class="u--block">${this.contact.name}</strong><span class="u--subtle u--fs13">${this.contact.email}</span>`
            }

            this.tippy = tippy(this.$el, {
                content: `<div style="text-align:center;">${content}</div>`,
                onHidden (instance) {
                    instance.destroy()
                }
            })
        },
        getInitials () {
            if (!this.contact) {
                return
            }

            let name = slugify(this.contact.name)
            if (name === '') {
                name = this.contact.email.substr(0, this.contact.email.indexOf('@')).replaceAll('.', ' ').replaceAll('-', ' ').replaceAll('_', ' ').replaceAll('+', ' ')
            }

            const result = name.split(' ').map(a => a.substring(0, 1).toUpperCase())
            if (result.length > 2) {
                return [result[0], result[result.length - 1]]
            } else if (result.length < 2) {
                return [name.substring(0, 1).toUpperCase(), name.substring(1, 2).toUpperCase()]
            }
            return result
        },
        onError () {
            this.state = 'error'
            // This is to avoid loading it multiple times when not needed
            this.$store.commit('contacts/UPDATE_ENTRY', { targetId: this.contact.id, document: { avatar: null } })
        }
    }
}
</script>

<style lang="scss">
.avatar__wrapper {position:relative;width:40px;height:40px;}
.icon__avatar {stroke:var(--grey-strong);width:20px;height:20px;vertical-align: middle;}
.tippy__suggestion_agents li:hover .icon__avatar, .tippy__suggestion_agents li.is-selected .icon__avatar {stroke:var(--blue);}
body.dark-mode .icon__avatar {width:16px;height:16px;margin-top:-4px;}
.avatar__badge {position:absolute;bottom:-2px;right:-2px;width:16px;height:16px;}
.avatar--unauth {
    mask-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MCA0MCI+PHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNDAgMEgwdjQwaDI5LjI4MUExMS41NDggMTEuNTQ4IDAgMCAxIDI2IDMxLjkyOGMwLS41MTUuMDM0LTEuMDMuMTAyLTEuNTQybC4wMDMtLjAxOS4wMDMtLjAxOWEyLjQgMi40IDAgMCAxIDIuMjgxLTIuMDM3IDcuNzY2IDcuNzY2IDAgMCAwIDQuNTY0LTEuNzU3bC4wMDMtLjAwMmEyLjQzNSAyLjQzNSAwIDAgMSAzLjA4OCAwbC4wMDMuMDAyQTcuNzU3IDcuNzU3IDAgMCAwIDQwIDI4LjI1N1YwWm0wIDM5LjdjLS4wOTIuMTAyLS4xODYuMjAyLS4yODEuM0g0MHYtLjNaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
}
.avatar--small .avatar__badge {width:10px;height:10px;}
.avatar--small .avatar__wrapper {width:24px;height:24px;}
.avatar__status {
    position:absolute;
    bottom:-1px;right:-1px;
    background:var(--green);width:10px;height:10px;border-radius:100%;
    border:2px solid var(--white);
}

.dark-mode .avatar__status {border:2px solid #1f2023}
.avatar {
    position: relative;

    &>img {
        position: absolute;
        top: 0;
        left: 0;
        visibility:hidden;
        opacity:0;
    }

    &.is-loaded>img {
        visibility:visible;
        opacity:1;
    }

    &-0 {
        background:#ede5ff;
        color:#9765FF;
    }

    &-1 {
        background:#D2F3E1;
        color:#37B56F;
    }

    &-2 {
        background:#FBEDD4;
        color:#E29918;
    }

    &-3 {
        background:#F3D1D1;
        color:#D73737;
    }

    &-4 {
        background:#DDF2FF;
        color:#01A0FF;
    }
}
</style>
