import Api from '@/resources/Api'
import { v4 as uuidv4 } from 'uuid'

export const baseState = () => ({
    items: {},
    mapId: {}
})

const defaults = {
    getters: {
        get: (state, getters) => (id, { resolve = null, reject = null } = {}) => {
            if (id == null) {
                if (resolve) resolve(null)
                return null
            }

            if (!(id in state.items)) {
                state.items[id] = null
                Api.stream(getters.endpointUrl({ path: id })).then(
                    () => {
                        if (resolve) resolve(state.items[id])
                    }, reject || (() => {})
                )
                return state.items[id]
            }

            if (resolve) resolve(state.items[id])
            return state.items[id]
        },
        getAsync: (state, getters) => async (id) => {
            /*
            Original:
            return new Promise((resolve, reject) => {
                getters.get(id, { resolve, reject })
            })
            */
            if (id == null) return null

            if (state.items[id] == null) {
                await Api.stream(getters.endpointUrl({ path: id }))
            }
            return state.items[id]
        },
        all: (state) => Object.values(state.items).filter(x => x !== null),
        endpointUrl: () => () => null,
        originalId: (state) => ($id) => (state.mapId ? state.mapId[$id] : null)
    },
    mutations: {
        RESET_STATE: () => {}, // No-op by default
        ON_LOADING_START: (state) => {
            state.$loading = true
        },
        ON_LOADING_STOP: (state) => {
            state.$loading = false
        },
        ADD_MAP_ID: (state, { $id, pk }) => {
            if (state.mapId) {
                state.mapId[$id] = pk
            }
        },
        ADD_ENTRY: (state, { targetId, document }) => {
            state.items[targetId] = document
        },
        UPDATE_ENTRY: (state, { targetId, document }) => {
            if (!(targetId in state.items)) return

            // We have, so we update
            Object.keys(document).forEach(k => {
                const shallowKey = '_' + k
                if (shallowKey in state.items[targetId]) {
                    // Exists as a shallow property
                    state.items[targetId][shallowKey].splice(0, state.items[targetId][shallowKey].length)
                    document[k].forEach(entry => {
                        state.items[targetId][shallowKey].push(entry)
                    })
                } else {
                    state.items[targetId][k] = document[k]
                }
            })
        },
        SET_NEW_STATE: (state, id) => {
            // Add a $new state to this document!
            if (id in state.items) {
                state.items[id].$new = true
                setTimeout(() => {
                    if (id in state.items) {
                        // Might have been deleted in between
                        delete state.items[id].$new
                    }
                }, 600) // The animation is 500ms
            }
        },
        REMOVE_ENTRY: (state, targetId) => {
            if (!(targetId in state.items)) return

            delete state.mapId[state.items[targetId].$id]
            delete state.items[targetId]
        },
        SET_REMOVED_STATE: (state, id) => {
            if (!(id in state.items)) return
            state.items[id].$removed = true
        },
        CANCEL_REMOVED_STATE: (state, id) => {
            if (!(id in state.items)) return
            delete state.items[id].$removed
        }
    },
    actions: {
        clear ({ commit }) {
            commit('RESET_STATE')
        },
        async _add ({ state, commit, dispatch, rootGetters }, struct) {
            /**
             * struct = {
             *      target_id
             *      document
             *      identifier
             * }
             */
            // Add comes from reading, we don't trigger the POST INSERT EVENT
            if (struct.target_id in state.items && state.items[struct.target_id] !== null) {
                // Already present, we update
                await dispatch('_update', struct)
                return false
            }

            if (!('$id' in struct.document)) {
                struct.document.$id = uuidv4()
                commit('ADD_MAP_ID', {
                    $id: struct.document.$id,
                    pk: struct.target_id
                })
            }

            commit('ADD_ENTRY', {
                targetId: struct.target_id,
                document: struct.document,
                identifier: struct.identifier,
                getters: rootGetters,
                commit
            })
            commit('SET_NEW_STATE', struct.target_id)

            // postAdd, contrary to postInsert, should act on any new inserted documents (read/insert)
            struct.server = struct.server || false
            await dispatch('postAdd', { id: struct.target_id, identifier: struct.identifier, document: struct.document, server: struct.server })
            return true
        },
        async postAdd () {},
        async _insert ({ state, dispatch }, struct) {
            /**
             * The difference between _add and _insert is that _add come froms a reading
             * While _insert is a new document. That means we trigger a POST INSERT event!
             */
            if (await dispatch('_add', struct)) {
                // Calls postInsert only if the document is new
                // _add returns false if the document was already present and only updated
                struct.server = struct.server || false
                await dispatch('postInsert', { id: struct.target_id, identifier: struct.identifier, document: state.items[struct.target_id], server: struct.server })
            }
        },
        async postInsert () {},
        async _fetch () {
            // Fetch is for document that were requested, but should already be present
            // For instance when searching.
        },
        async _update ({ commit, dispatch }, struct) {
            commit('UPDATE_ENTRY', { targetId: struct.target_id, document: struct.document })
            struct.server = struct.server || false
            await dispatch('postUpdate', { id: struct.target_id, identifier: struct.identifier, document: struct.document, server: struct.server })
        },
        async postUpdate () {},
        async _delete ({ state, commit, dispatch }, struct) {
            if (struct.target_id in state.items) {
                struct.server = struct.server || false
                struct.document = state.items[struct.target_id]
                commit('SET_REMOVED_STATE', struct.target_id)
                setTimeout(() => {
                    commit('REMOVE_ENTRY', struct.target_id)
                }, 1000)
                dispatch('postDelete', { id: struct.target_id, identifier: struct.identifier, document: struct.document, server: struct.server }) // We don't await the postUpdate though
            }
        },
        async postDelete () {},
        async all ({ getters, commit }) {
            commit('ON_LOADING_START')
            // Don't put this as async, or it will be stuck waiting for the rest
            Api.stream(getters.endpointUrl()).then(_ => commit('ON_LOADING_STOP'))
        },
        async create ({ state, getters, dispatch }, form) {
            const result = await Api.post(getters.endpointUrl(), form)
            dispatch('_insert', {
                target_id: result.id,
                document: result
            })
            return state.items[result.id]
        },
        async update ({ state, getters, commit, dispatch }, { id, $id, document }) {
            let instance = null
            let targetId = null
            if (id) {
                targetId = id
                instance = state.items[id]
            } else {
                targetId = getters.originalId($id)
                instance = state.items[targetId]
            }

            // Two scenarios here:
            // 1. We don't have an endpoint URL, so we apply the changes directly
            // 2. We have an endpoint URL, so we make a diff, apply the changes and send them.
            //    In case of error, we fail the promise and revert the changes

            const diff = {}
            Object.keys(document).forEach(k => {
                if (document[k] !== instance[k]) {
                    diff[k] = instance[k]
                }
            })

            // First thing first, happy assumption, we trigger the standard behavior, regardless of what the endpoint (if any) will say
            commit('UPDATE_ENTRY', { targetId, document })
            dispatch('postUpdate', { id: targetId, document, server: false }) // We don't await the postUpdate though
            const endpointUrl = getters.endpointUrl({ instance })
            if (endpointUrl) {
                try {
                    // X-Notification-Disable is null by default (enabled),
                    // But can be "sender": we don't want to receive the event back
                    // Or "everyone": No updates event will be sent accross all members
                    await Api.patch(endpointUrl, document, { headers: { 'X-Notification-Disable': 'sender' } })
                } catch (e) {
                    // We revert and throw the error
                    commit('UPDATE_ENTRY', { targetId, document: diff })
                    dispatch('postUpdate', { id: targetId, document: diff, server: false }) // we postUpdate the status too
                    throw e
                }
            }
        },
        async remove ({ state, getters, commit }, { $id = null, id = null }) {
            if ($id) {
                id = state.mapId[$id]
            }

            const instance = state.items[id]
            const endpointUrl = getters.endpointUrl({ instance })

            // In the case of removed state from the client, we only mark as removed
            // But the rest will be done by the request coming from the server
            commit('SET_REMOVED_STATE', id)
            if (endpointUrl) {
                try {
                    await Api.delete(endpointUrl)
                } catch (e) {
                    commit('CANCEL_REMOVED_STATE', id)
                    throw e
                }
            }
        }
    }
}

export const buildEndpointUrl = (baseUrl, key, { instance = null, path = '' } = {}) => {
    if (instance) {
        const pk = instance[key]
        return `${baseUrl}${pk}${path}`
    }

    if (path && path.toString().substr(0, 1) === '/') path = path.toString().substr(1)
    return `${baseUrl}${path}`
}

export default defaults
